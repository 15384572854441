module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-delayed/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5Q29X9","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"157b1bb8e0a76c60e1b434c8ddfe5540"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
